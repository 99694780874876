<template>
  <div
    class="h-full"
    data-el="hero-slide"
  >
    <div
      class="relative h-full"
    >
      <div class="aspect-[3/2] lg:aspect-[123/55] [&>picture]:size-full [&>picture]:object-cover [&>picture]:object-center">
        <slot />
      </div>

      <div class="w-full bg-primary py-4 lg:absolute lg:inset-0 lg:bg-transparent lg:pt-12">
        <div class="container flex h-full flex-col lg:pl-12">
          <div
            class="z-20 mb-11 flex size-full flex-wrap content-between md:mb-0 md:block md:h-auto lg:content-none xl:w-1/2"
          >
            <div class="w-full">
              <HtmlParser
                v-if="props.title"
                :html="props.title"
                tag="h2"
                class="mb-0 whitespace-pre-wrap text-xl text-white drop-shadow-lg xl:text-3xl"
              />
              <p
                v-if="props.subtitle"
                class="mt-3 text-base text-white drop-shadow-md xl:mt-8 xl:text-xl"
              >
                {{ props.subtitle }}
              </p>
            </div>
            <UiButton
              v-if="props.linkText"
              class="mt-5 w-full text-base drop-shadow-md sm:w-fit xl:mt-8"
              icon-right="arrow"
              :very-slim="true"
              ssr-icon
              :to="props.link"
            >
              {{ props.linkText }}
            </UiButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import UiButton from '../UiButton/UiButton.vue'

const props = defineProps<{
  title: string
  subtitle: string
  link: string | undefined
  linkText: string | undefined
}>()
</script>
